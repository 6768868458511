import { render, staticRenderFns } from "./PurchasesList.view.vue?vue&type=template&id=39cd4bdf&"
import script from "./PurchasesList.view.vue?vue&type=script&lang=js&"
export * from "./PurchasesList.view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-fl223/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39cd4bdf')) {
      api.createRecord('39cd4bdf', component.options)
    } else {
      api.reload('39cd4bdf', component.options)
    }
    module.hot.accept("./PurchasesList.view.vue?vue&type=template&id=39cd4bdf&", function () {
      api.rerender('39cd4bdf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rearchitected/themes/atmo/views/pages/purchases/list/PurchasesList.view.vue"
export default component.exports