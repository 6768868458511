import { render, staticRenderFns } from "./ContractAnnex.view.vue?vue&type=template&id=042f0271&scoped=true&"
import script from "./ContractAnnex.view.vue?vue&type=script&lang=js&"
export * from "./ContractAnnex.view.vue?vue&type=script&lang=js&"
import style0 from "./ContractAnnex.view.vue?vue&type=style&index=0&id=042f0271&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "042f0271",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-fl223/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('042f0271')) {
      api.createRecord('042f0271', component.options)
    } else {
      api.reload('042f0271', component.options)
    }
    module.hot.accept("./ContractAnnex.view.vue?vue&type=template&id=042f0271&scoped=true&", function () {
      api.rerender('042f0271', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rearchitected/themes/atmo/views/pages/purchases/item/view/partials/contract/annexes/ContractAnnex.view.vue"
export default component.exports