'use strict';

import { Store } from 'vuex';
import VueRouter, { Route } from 'vue-router';
import upperFirst from 'lodash.upperfirst';
import isEmpty from 'lodash.isempty';

import CommonRequest from '@core/js/ddriven/application/http/requests/common/CommonRequest';
import PurchasesRouteDataService from './services/PurchasesRouteDataService';
import ContractsRouteDataService from './services/ContractsRouteDataService';
import NoticesRouteDataService from './services/NoticesRouteDataService';

export default class RouteDataServiceFacade {
    [index: string]: Record<string, any>;

    private static readonly allowedEntityRoutes: string[] = ['purchases.grouped', 'contracts.grouped', 'purchases.grouped.item.view', 'purchases.grouped.item.create', 'purchases.grouped.item.edit', 'notices.commercial', 'notices.fl44'];

    store: Store<unknown>;
    router: VueRouter;
    purchases: PurchasesRouteDataService;
    contracts: ContractsRouteDataService;
    notices: NoticesRouteDataService;

    constructor(store: Store<unknown>, router: VueRouter) {
        this.store = store;
        this.router = router;
        this.purchases = new PurchasesRouteDataService(this);
        this.contracts = new ContractsRouteDataService(this);
        this.notices = new NoticesRouteDataService(this);
    }

    public static isAllowedEntityRoute(routeName: string) {
        return RouteDataServiceFacade.allowedEntityRoutes.indexOf(routeName) > -1;
    }

    public static routeRoot(path: string): string {
        const pathParsed = path.match(/(?!\/)([a-z0-9]+)/);
        if (!pathParsed || !pathParsed[0]) {
            throw new Error(`ATMO Exception: the route path ("${path}") is in unexpected format.`);
        }

        return pathParsed[0];
    }

    public async load(to: Route, from: Route) {
        if (!to.name) {
            throw new Error(`ATMO Exception: the "name" property must be set for route "${to.path}".`);
        }

        const entityName = this.entityName(to.path);
        const methodName = this.methodName(to.name);

        if (typeof this[entityName] !== 'object' || typeof this[entityName][methodName] !== 'function') {
            throw new Error(`ATMO Exception: either the object ${this.constructor.name}.${entityName} does not exist or the method ${this.constructor.name}.${entityName}.${methodName} does not exist.`);
        }

        return await this[entityName][methodName](to, from);
    }

    public loadEntitiesGrouped(entityName: string, request: CommonRequest, query: Route['query'], to: Route): void {
        let group = to.params.group;

        // if (to.name === 'notices') {
        //     group = 'global';
        // }
        if (to.name === 'notices.commercial') {
            group = 'commercial';
        }
        if (to.name === 'notices.fl44') {
            group = 'fl44';
        }

        if (!group) {
            throw new Error('ATMO Exception: the route params "group" must be defined in route config.');
        }

        /**
         * If query is empty object (means the entire root reset is happening)
         * need to somehow reset internal PurchaseRequestFilter to empty state
         * in the PurchaseFiltersController.
         *
         * The effect of routerest is only comes when it changes its state
         * to true. There is no other place to return the state to false.
         * So do it here to obtain the desired result of change from false to true.
         */
        this.store.commit(`rearchitected/groups/${group}/${entityName}/routereset`, isEmpty(query) ? true : false);

        // const request = PurchaseRequest.fromQueryObject(query, PurchaseRequest) as PurchaseRequest;
        this.store.commit(`rearchitected/groups/${group}/${entityName}/setRequest`, request);
        this.store.dispatch(`rearchitected/groups/${group}/${entityName}/load`, request);
    }

    private entityName(path: string): string {
        return RouteDataServiceFacade.routeRoot(path);
    }

    private methodName(routeName: string): string {
        const temp = routeName.replace(/(\.[A-Za-z])/g, (match) => {
            return match.replace('.', '').toUpperCase();
        });
        return `load${upperFirst(temp)}`;
    }
}
