import Dummy from '@/components/Dummy.vue';

export default [
    {
        path: '/notices',
        component: () => Dummy,
        params: { group: 'global' },
        name: 'GlobalNoticesList'
    },
    {
        path: '/notices/fl223',
        redirect: { name: 'Fl223NoticesList', params: { status: 'accepting' } },
        component: () => import(/* webpackChunkName: "main" */ '@/components/global/fl223-notices/fl223-notices-list.vue'),
        children: [
            {
                path: ':status(all|accepting|summarizing|summarized|cancelled|failed|contract:active)',
                name: 'Fl223NoticesList',
                component: () => import(/* webpackChunkName: "main" */ '@/components/global/fl223-notices/fl223-notices-list.vue'),
            }
        ]
    },
    // {
    //     path: '/notices/fl44',
    //     component: () => import(/* webpackChunkName: "main" */ '@/components/global/fl44-notices/notices-list.vue'),
    //     params: { group: 'global' },
    //     name: 'Fl44NoticesList'
    // }
];
