var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        visible: _vm.show,
        centered: "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-footer": "",
        size: "md",
        title: _vm.title,
      },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header-close",
          fn: function () {
            return [_c("button-close-modal")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("text-row", {
        staticClass: "pl-0",
        attrs: {
          label: _vm.orgIsCustomer ? "Заказчик" : "Поставщик",
          value: _vm.signature.organization_name,
          compact: "",
        },
      }),
      _c("text-row", {
        attrs: { label: "ФИО", value: _vm.signature.user_name, compact: "" },
      }),
      _vm.signature.poa_check_link
        ? _c("text-row-link", {
            attrs: {
              label: "МЧД",
              href: _vm.signature.poa_check_link,
              value: _vm.signature.poa_number,
              compact: "",
            },
          })
        : _vm._e(),
      _c("text-row-datetime", {
        attrs: { label: "Дата и время", value: _vm.signature.sign_datetime },
      }),
      _c("div", { staticClass: "signature" }, [
        _c("pre", { staticClass: "sign-text" }, [
          _vm._v(
            "                " + _vm._s(_vm.signature.md5) + "\n            "
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }