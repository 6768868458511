import Config from '@core/js/ddriven/application/config/Config.ts';

export const __OFFERS_STATUS = {
    status_actual: 1,
    status_revoked: 2,
    status_declined: 3,
    status_winner: 4
};
export const __PURCHASE_STATUS = {
    status_start: 1,
    status_protocol_formed: 2,
    status_contract_concluded: 3,
    status_contract_executed: 4,
    status_contract_not_executed: 5,
    status_contract_terminated: 6,
    status_cancel: 7,
    status_fail: 8,
    status_control: 9,
    status_contract_not_concluded: 10,
    status_draft: 11,
    status_blocked: 12,
    status_draft_save_signed: 13
};
export const __CONTRACT_STATUS = {
    status_document_send: 1,
    status_document_signed_provider: 2,
    status_document_signed_provider_customer: 3,
    status_protocol_disagreement: 4
};
export const __LINKS = {
    // Info.
    user_agreement: Config.get('theme.variables.links.info.user_agreement'),
    privacy_policy: Config.get('theme.variables.links.info.privacy_policy'),
    public_offer: Config.get('theme.variables.links.info.public_offer'),
    user_guide: Config.get('theme.variables.links.info.user_guide'),
    useful_links: Config.get('theme.variables.links.info.useful_links'),
    legal_documents: Config.get('theme.variables.links.info.legal_documents'),
    info_materials: Config.get('theme.variables.links.info.information_materials'),
    videos: Config.get('theme.variables.links.info.videos'),
    faq: Config.get('theme.variables.links.info.faq'),
    add_money: Config.get('theme.variables.links.info.add_money'),
    interactive_instructions: 'https://info.zakupschik.ru/264--interaktivnye-instrukcii/',
    telegram_channel: 'https://t.me/+KI4yoVjEfpljN2Ri',

    // Manuals.
    ktru_requests_video_manual: Config.get('theme.variables.links.manuals.video_add_ktru_requests'),
    manual_ktru_requests_add: Config.get('theme.variables.links.manuals.text_add_ktru_requests'),
    purchase_categories: Config.get('theme.variables.links.manuals.purchase_categories'),
    purchase_specification_manual: Config.get('theme.variables.links.manuals.specification'),
    new_offer_manual: Config.get('theme.variables.links.manuals.create_new_offer'),
    external_purchase_create_manual: Config.get('theme.variables.links.manuals.create_external_purchase'),
    external_purchase_edit_manual: Config.get('theme.variables.links.manuals.edit_external_purchase'),
    import_substitution_request_manual: Config.get('theme.variables.links.manuals.import_substitution'),

    fl223_purchase_create_manual: Config.get('theme.variables.links.manuals.fl223_purchase_create'),
    fl223_purchase_winner_selection_manual: Config.get('theme.variables.links.manuals.fl223_purchase_winner_selection'),
    fl223_purchase_contract_manual: Config.get('theme.variables.links.manuals.fl223_purchase_contract'),

    commercial_purchase_create_manual: Config.get('theme.variables.links.manuals.commercial_purchase_create'),
    commercial_purchase_winner_selection_manual: Config.get('theme.variables.links.manuals.commercial_purchase_winner_selection'),

    fl44_purchase_reports_manual: 'https://app.hinted.me/simulations/9a885d16-d2dc-469b-983b-8c189ca65dd7/view',

    // Other.
    prolong_certificate: Config.get('theme.variables.links.other.prolong_certificate'),
    generate_poa: 'https://kontur.ru/mchd?p=w06131',
    marketplace_register: Config.get('theme.variables.links.other.marketplace_registration'),
    marketplace_link: Config.get('theme.variables.links.other.marketplace_link') ?? null,
    telegram_events_bot: Config.get('theme.variables.links.other.telegram_bot')
};

export const __CONTRACT_SUPPLEMENTARY_AGREEMENT_STATUSES = [
    { id: 1, text: 'Отправлено поставщику на подписание' },
    { id: 2, text: 'Подписано поставщиком' },
    { id: 3, text: 'Подписано поставщиком и заказчиком' },
    { id: 4, text: 'Поставщик направил протокол разногласий' }
];

export const PurchaseStatus = {
    STATUS_START: 1,
    STATUS_PROTOCOL_FORMED: 2,
    STATUS_CONTRACT_CONCLUDED: 3,
    STATUS_CONTRACT_EXECUTED: 4,
    STATUS_CONTRACT_NOT_EXECUTED: 5,
    STATUS_CONTRACT_TERMINATED: 6,
    STATUS_CANCEL: 7,
    STATUS_FAIL: 8,
    STATUS_CONTROL: 9,
    STATUS_CONTRACT_NOT_CONCLUDED: 10,
    STATUS_DRAFT: 11,
    STATUS_BLOCKED: 12,
    STATUS_DRAFT_SAVE_SIGNED: 13
};

export const RefundRequestStatus = [
    { id: 1, name: 'Отправлен' },
    { id: 2, name: 'Исполнен' },
    { id: 3, name: 'Отклонен' }
];

export const Fl223 = {
    PurchaseLengthTypes: [
        { id: 1, name: 'Срочная', minLength: 2, maxLength: 6, postfix: ', часов' },
        { id: 2, name: 'Не срочная', minLength: 2, maxLength: null, postfix: ', дней' }
    ],
    PurchaseStatuses: [
        { id: 1, name: 'Черновик', iconImage: '/images/empty-pixel.svg', subHeaderClass: 'card-header-grey' },
        { id: 2, name: 'Сохранен и подписан', iconImage: '/images/empty-pixel.svg', subHeaderClass: 'card-header-grey' },
        { id: 3, name: 'Заблокирован', iconImage: '/images/empty-pixel.svg', subHeaderClass: 'card-header-yellow' },
        { id: 4, name: 'Идет прием предложений', iconImage: '/images/secundomer.svg', subHeaderClass: 'card-header-grey' },
        { id: 5, name: 'Подведение итогов', iconImage: '/images/empty-pixel.svg', subHeaderClass: 'card-header-grey' },
        { id: 6, name: 'Подведены итоги', iconImage: '/images/white_helmet.svg', subHeaderClass: 'card-header-green' },
        { id: 7, name: 'Закупка отменена', iconImage: '/images/white_times.svg', subHeaderClass: 'card-header-yellow' },
        { id: 8, name: 'Закупка не состоялась', iconImage: '/images/white_times.svg', subHeaderClass: 'card-header-yellow' },
        { id: 9, name: 'Договор не заключен', iconImage: '/images/empty-pixel.svg', subHeaderClass: 'card-header-yellow' },
        { id: 10, name: 'Договор заключен', iconImage: '/images/white_contract.svg', subHeaderClass: 'card-header-green' },
        { id: 11, name: 'Договор исполнен', iconImage: '/images/file_check.svg', subHeaderClass: 'card-header-blue' },
        { id: 12, name: 'Договор не исполнен', iconImage: '/images/white_times.svg', subHeaderClass: 'card-header-grey' },
        { id: 13, name: 'Договор расторгнут', iconImage: '/images/white_times.svg', subHeaderClass: 'card-header-grey' }
    ],
    ContractFormats: [
        { id: 1, name: 'В электронном виде' },
        { id: 2, name: 'На бумаге' }
    ]
};

export const Fl223Statuses = {
    LENGTH_TYPE_FAST: 1,
    LENGTH_TYPE_NORMAL: 2,

    STATUS_DRAFT: 1,
    STATUS_SAVED_SIGNED: 2,
    STATUS_BLOCKED: 3,
    STATUS_START: 4,
    STATUS_PROTOCOL_FORMING: 5,
    STATUS_PROTOCOL_FORMED: 6,
    STATUS_CANCEL: 7,
    STATUS_FAIL: 8,
    STATUS_CONTRACT_NOT_CONCLUDED: 9,
    STATUS_CONTRACT_ON_CONTROL: 9 - 1,
    STATUS_CONTRACT_CONCLUDED: 10,
    STATUS_CONTRACT_EXECUTED: 11,
    STATUS_CONTRACT_NOT_EXECUTED: 12,
    STATUS_CONTRACT_TERMINATED: 13,

    CONTRACT_FORMAT_SYSTEM: 1,
    CONTRACT_FORMAT_PAPER: 2
};

export const OrgRoles = {
    CUSTOMER_FL44: 'customer_fl44',
    CUSTOMER_FL223: 'customer_fl223',
    CUSTOMER_COMMERCIAL: 'customer_commercial',
    SUPPLIER_FL44: 'supplier_fl44',
    SUPPLIER_FL223: 'supplier_fl223',
    SUPPLIER_COMMERCIAL: 'supplier_commercial'
};
