<template>
    <b-form-group label-class="fs-14 grey-color fw-700 d-flex align-items-baseline" la :label-cols-lg="labelColsLg" :label-cols-sm="labelColsSm">
        <template #label>
            {{ title }}:
            <div v-if="!!hint" class="atmo-help">
                &nbsp;<i class="atmo-icon-question-mark"></i><span class="atmo-help__text">{{ hint }}</span>
            </div>
        </template>
        <div class="d-flex justify-content-between">
            <template v-if="!!document">
                <a class="fs-14 col-form-label my-auto" :href="$getAttachmentDownloadLink(document.id)" target="_blank">{{ document.name }}</a>
                <span class="float-right my-auto">
                    <b-button v-if="withSend" @click="$emit('send')" variant="custom-green-small" :disabled="disabled" class="mr-2"><b-icon icon="reply" class="my-auto mr-2" aria-hidden="true" />{{ sendTitle }}</b-button>
                    <action-button-small icon="trash" @click="removeFile(0, true)" :disabled="disabled"  />
                </span>
            </template>
            <template v-else>
                <p :class="!state ? 'text-danger' : ''" class="fs-14 col-form-label my-auto">Прикрепите документ</p>
                <file-uploader :is-disabled="!!document || disabled" :is-main="true" class="float-right my-auto" @set_file="addFile">Загрузить</file-uploader>
            </template>
        </div>
    </b-form-group>
</template>

<script>
export default {
    name: 'FormRowSingleLineDocumentUpload',
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: Object
        },
        v: {
            type: Object
        },
        required: {
            type: Boolean,
            default: true
        },
        headerClass: {
            type: String,
            default: 'fs-32'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        labelColsLg: {
            default: 6
        },
        labelColsSm: {
            default: 4
        },
        hint: {
            type: String,
            default: ''
        },
        withSend: {
            type: Boolean,
            default: false
        },
        sendTitle: {
            type: String,
            default: 'Отправить'
        }
    },
    methods: {
        addFile(file) {
            this.document = file;
        },
        removeFile() {
            this.document = null;
        }
    },
    computed: {
        document: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        state() {
            return typeof this.v !== 'undefined' && this.v !== null ? !this.v.$invalid : null;
        }
    }
};
</script>

<style scoped>
.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    vertical-align: middle;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}

a {
    color: var(--green-color) !important;
}
</style>
