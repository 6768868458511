<template>
    <b-modal :visible="show" centered no-close-on-backdrop no-close-on-esc hide-footer size="md" :title="title" @close="$emit('close')">
        <template #modal-header-close>
            <button-close-modal />
        </template>
        <text-row :label="orgIsCustomer ? 'Заказчик' : 'Поставщик'" :value="signature.organization_name" class="pl-0" compact />
        <text-row label="ФИО" :value="signature.user_name" compact />
        <text-row-link v-if="signature.poa_check_link" label="МЧД" :href="signature.poa_check_link" :value="signature.poa_number" compact />
        <text-row-datetime label="Дата и время" :value="signature.sign_datetime" />
        <div class="signature">
                <pre class="sign-text">
                    {{ signature.md5 }}
                </pre>
        </div>
    </b-modal>
</template>

<script>

export default {
    name: 'show-sign-modal',
    props: {
        title: {
            type: String,
            default: 'Электронная подпись',
        },
        show: {
            type: Boolean,
            default: false
        },
        orgIsCustomer: {
            type: Boolean,
            default: false
        },
        signature: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
.signature {
    height: 100px;
    overflow: auto;
    font-size: 0.75em;
    line-break: anywhere;
}
.sign-text {
    white-space: pre-line;
}
</style>
