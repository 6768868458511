<template>
    <b-modal :visible="show" centered no-close-on-backdrop no-close-on-esc size="md" :title="title" @show="sign" @close="$emit('close')">
        <template #modal-header-close>
            <button-close-modal />
        </template>
        <h6 class="fw-600">Файл</h6>
        <p class="fs-14">{{ attachment.name }}</p>
        <h6 class="fw-600">Подпись</h6>
        <div class="signature">
            <pre class="sign-text">
                {{ signature }}
            </pre>
        </div>
        <form-row-edit-textarea v-if="withComment" label="Комментарий" v-model="form.comment" :v="$v.form.comment" label-on-top compact />
        <div slot="modal-footer" class="w-100 text-right">
            <b-overlay :show="isSigning" rounded opacity="0.6" spinner-small spinner-variant="primary">
                <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="$emit('close')">Отмена</b-button>
                <b-button class="text-uppercase" variant="custom-green" :disabled="!signature || $v.form.$invalid" @click="$emit('submit', { signature: signature, comment: form.comment })">{{ submitTitle }}</b-button>
            </b-overlay>
        </div>
    </b-modal>
</template>

<script>
import { createDetachedSignature } from "crypto-pro";
import { requiredIf } from "vuelidate/lib/validators";

export default {
    name: 'sign-file-modal',
    props: {
        title: {
            type: String,
            required: true
        },
        show: {
            type: Boolean,
            default: false
        },
        submitTitle: {
            type: String,
            default: 'Отправить'
        },
        attachment: {
            type: Object,
            required: true
        },
        withComment: {
            type: Boolean,
            default: false
        },
        commentRequired: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: {
                comment: '',
                commentRequired: false
            },
            isSigning: false,
            signature: ''
        };
    },
    validations() {
        return {
            form: {
                comment: {
                    required: requiredIf((form) => form.commentRequired)
                }
            }
        }
    },
    methods: {
        async sign() {
            this.form.commentRequired = this.commentRequired;
            this.form.comment = '';
            try {
                this.isSigning = true;
                this.signature = (await createDetachedSignature(this.$store.getters.getCurrentThumbprint, this.attachment.hash));
                this.showConfirmModal = true;
            } catch (error) {
                await this.$store.dispatch('showDangerToast', error.message);
            } finally {
                this.isSigning = false;
            }
        }
    }
};
</script>

<style scoped>
.signature {
    height: 100px;
    overflow: auto;
    font-size: 0.75em;
    line-break: anywhere;
}
.sign-text {
    white-space: pre-line;
}
</style>
