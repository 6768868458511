var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        visible: _vm.show,
        centered: "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        size: "md",
        title: _vm.title,
      },
      on: {
        show: _vm.sign,
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header-close",
          fn: function () {
            return [_c("button-close-modal")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("h6", { staticClass: "fw-600" }, [_vm._v("Файл")]),
      _c("p", { staticClass: "fs-14" }, [_vm._v(_vm._s(_vm.attachment.name))]),
      _c("h6", { staticClass: "fw-600" }, [_vm._v("Подпись")]),
      _c("div", { staticClass: "signature" }, [
        _c("pre", { staticClass: "sign-text" }, [
          _vm._v("            " + _vm._s(_vm.signature) + "\n        "),
        ]),
      ]),
      _vm.withComment
        ? _c("form-row-edit-textarea", {
            attrs: {
              label: "Комментарий",
              v: _vm.$v.form.comment,
              "label-on-top": "",
              compact: "",
            },
            model: {
              value: _vm.form.comment,
              callback: function ($$v) {
                _vm.$set(_vm.form, "comment", $$v)
              },
              expression: "form.comment",
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "w-100 text-right",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer",
        },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                show: _vm.isSigning,
                rounded: "",
                opacity: "0.6",
                "spinner-small": "",
                "spinner-variant": "primary",
              },
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase mr-2",
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Отмена")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: {
                    variant: "custom-green",
                    disabled: !_vm.signature || _vm.$v.form.$invalid,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("submit", {
                        signature: _vm.signature,
                        comment: _vm.form.comment,
                      })
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.submitTitle))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }